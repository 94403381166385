<template>
    <div class="main">
      <section v-for="img in imgList">
        <img :src="img" alt="" />
      </section>
    </div>
  </template>
  
  <script>
    export default {
      name: 'ContentManagementRules',
      data() {
        return {
          imgList: [
            'https://static1.kaixinyf.cn/img/lza6343e67792871621184949.png',
            'https://static1.kaixinyf.cn/img/lza6343e68b6a5fb989564122.png',
            'https://static1.kaixinyf.cn/img/lza6343e697cfa7f318141762.png',
            'https://static1.kaixinyf.cn/img/lza6343e6a733c62566712142.png',
          ],
        };
      },
      mounted() {
        document.title = '【北京闪光弹科技】音乐作品授权使用协议';
      },
    };
  </script>
  
  <style scoped>
    body {
      font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
        sans-serif;
      margin: 0;
      padding: 0;
      width: 100%;
      background-color: #eff0f1;
      /*padding-left: constant(safe-area-inset-left);*/
      /*padding-left: env(safe-area-inset-left);*/
      /*padding-right: constant(safe-area-inset-right);*/
      /*padding-right: env(safe-area-inset-right);*/
      /*padding-bottom: constant(safe-area-inset-bottom);*/
      /*padding-bottom: env(safe-area-inset-bottom);*/
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #eff0f1;
    }
    section {
      margin-bottom: 18px;
    }
    section img {
      max-width: 980px;
    }
  </style>
  